import React from "react";
import './hero.css';

const Herosection = () => {
    return (
        <>
        
            <div className="herosection-container">
                <div className="hero-box">
                    <h1 className="hero-title">Pushpak drone viman</h1>
                </div>
            </div>
        </>
    )
}

export default Herosection;