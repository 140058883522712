import React from 'react';
import './contacthero.css';

const Contacthero = () => {
  return (
   <>
    <div className='contactus-hero-container'>
        <div className='contactus-heading'>Contact Us</div>
      </div>
   </>
  )
}

export default Contacthero