import React from 'react';
import './serviceshero.css';

const Serviceshero = () => {
  return (
    <>
    <div className='Serviceshero-container'>
      <div className='aboutus-heading'>Services</div>
    </div>
  </>
  )
}

export default Serviceshero