import React from 'react';
import './hero.css';

const Blankspace = () => {
  return (
    <>
        <div className="blank-box"></div>
    </>
  )
}

export default Blankspace