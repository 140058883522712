import React from 'react';
import './traininghero.css';


export const Traininghero = () => {
  return (
    <>
    <div className='traininghero-container'>
      <div className='aboutus-heading'>Training</div>
    </div>
  </>
  )
}
