const nullimg="https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/temporary-image.png?alt=media&token=9a903ec7-2bc8-4d62-8a3b-2db8b1c1082a";

const nulltext="null text"


const pdata=[
    {
            title: "Agriculture Drone",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2Fagiculture%20drone%203.jpg?alt=media&token=e5a6f238-89d1-418d-830a-13cbf576225c",
            contant:"Leading the way in agricultural precision, I offer assembled and tested spraying drones through my startup at competitive rates. Delivering optimal solutions to ensure your fields thrive,plus enjoy a complimentary 6-month service for sustained success 1. Precision Drones  2. Reliable Solutions 3. Competitive Rates 4. Thriving Fields 5. Complimentary Service "
    },
//     {
//             title: "Surveillance Drone",
//             imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2Fsrvillancedrone.jpg?alt=media&token=b10f62c4-9880-40cb-83fa-e34181e08296",
//             contant:" Elevating security with innovation! Our startup specializes in surveillance drones on 3D printed frames, providing customers with cutting-edge solutions at competitive rates.Your safety, our priority!  1. Innovative Security   2. Surveillance Drones  3. 3D Printed Frames  4. Cutting-edge Solutions 5. Priority Safety"
//     },
    {
            title: "Videography Drone",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2FSlide20.JPG?alt=media&token=0bab167d-711d-4553-95a4-3196d22aa588",
            contant:"Pushpak Drone Viman is equipped with state-of-the-art aerial photography and videography capabilities. Our drones feature high-resolution cameras with advanced stabilization technology to capture stunning images and videos from various angles and altitudes. With precise GPS navigation systems and intelligent flight controls, our drones ensure smooth and stable operation even in challenging environments 1).Wedding Photography/Videography  2).Construction and Surveying"
    },
    {
            title: "Mapping Drone",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2FSlide24.JPG?alt=media&token=67b2c71d-c566-46a9-8117-6a7c1a53d2e7",
            contant:"  Mapping drones navigate the skies with precision, utilizing advanced technology to create detailed and accurate maps. Equipped with specialized sensors, they survey landscapes, monitor terrain changes, and contribute to urban planning and environmental management. These unmanned aerial vehicles revolutionize mapping processes, providing efficient and high-resolution data for various industries. 1. Precision Mapping  2. Landscape Survey  3. Urban Planning"
    },
    {
            title: "Drone Components / Kit",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2Fcomponents%26kits.png?alt=media&token=f13a3932-c14b-4cdc-8ff8-c2fb32b037de",
            contant:"Pushpak Drone Viman, where we specialize in providing drone kits for enthusiasts and professionals alike. Our kits are tailored to meet the needs of both commercial purposes and educational training in the drone industry. Explore our wide range of drone components designed to empower your aerial endeavours."
    },
    {
            title: "Customised Objects",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2Fcustomobject.jpg?alt=media&token=fe07d378-eb5b-479a-9aaa-e7271b1e3d20",
            contant:"  Embarking on my startup journey, I specialize in crafting customized products through 3D printing,tailoring each creation to match the unique needs of my customers. 1. Startup Crafting 2. Customized Products 3. 3D Printing 4. Tailored Creations 5. Unique Solutions"
    },
    {
            title: "Lithographic 3D Printing",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2FPicture1.png?alt=media&token=7ef11d93-b843-4040-be88-8e9cc5c22e05",
            contant:"Turning photos into 3D objects gives them more impact and literally adds a new dimension. Lithophanes offer a simple method for enhancing your favorite photos. Essentially, lithophanes are embossed photos generated by a 3D printer. Initially, the print results may not reveal much, but when light is shone through one, the details become astonishing. The process operates by allowing light to pass through the thin parts while being obstructed by the thick parts, thereby highlighting the image details."
    },
    {
            title: "3D Printed objects",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2Fprinted%20object.jpg?alt=media&token=16b3e089-a8ff-4d7b-ab52-1669addfd229",
            contant:" Turning moments into treasures with my startup! Specializing in 3D printed gifts like Lord Ganesh idols,Lord Adiyogi replicas, and practical items like mobile stands. Personalized perfection for every occasion!  1.Your vision, our creation  2.Custom Designs  3.3D Printing 4.Innovation"
    },
    {
            title: "Educational Tools",
            imgsrc: "https://firebasestorage.googleapis.com/v0/b/pushpak-drone-viman-pvt-ltd.appspot.com/o/Product%2Feductional%20tool.png?alt=media&token=44f4aeac-74bb-4298-a99b-34d8948cbeb3",
            contant:" Empowering education through my startup! Specializing in 3D printing for the educational field, providing students with tangible models to enhance understanding of physics, chemistry bonding, and biological concepts. Bridging the gap between imagination and learning  1.Education Innovation  2.3D Printing Specialization  3.Enhancing Understanding in Physics, Chemistry, and Biology Concepts"
    },
    {
            title: "Microcontroller",
            imgsrc: nullimg,
            contant:nulltext
    },
    {
            title: "Microcomputer",
            imgsrc: nullimg,
            contant:nulltext
    },
    {
            title: "Soldering Equipment",
            imgsrc: nullimg,
            contant:nulltext
    },
    {
            title: "All Type Sensor",
            imgsrc: nullimg,
            contant:nulltext
    },
    {
            title: "IoT Based Kit",
            imgsrc: nullimg,
            contant:nulltext
    },
]

export default pdata;
