import React from 'react';
import './productpage.css';
import Footer from '../../Basic components/Footer';
import Maincontainerimg from '../components/Maincontainerimg';
import Subpageheading from '../components/Subpageheading';
import Paragraph from '../components/Paragraph';
import Advantagesheading from '../components/Advantagesheading';
import AdvantagesImages from '../components/AdvantagesImages';
import Blankspace from '../../Basic components/Blankspace';
import pData from '../subpage data/Product Data/Productdata';

const Lithographicprinting = () => {
  return (
    <>
        <Blankspace />
            <div className='subpage-main-container'>
                <div className='upper-border'>
                    <div className='lower-border'>
                        <Maincontainerimg imgsrc={pData[5].imgloc} />
                        <Subpageheading topheading={pData[5].heading} />
                        <div className='subpage-content'>
                            <Paragraph paratext="Turning photos into 3D objects gives them more impact and literally adds a new dimension. Among the various ways to accomplish this, lithophanes offer a simple method for enhancing your favorite photos. Essentially, lithophanes are embossed photos generated by a 3D printer. Initially, the print results may not reveal much, but when light is shone through one, the details become astonishing. The process operates by allowing light to pass through the thin parts while being obstructed by the thick parts, thereby highlighting the image details. In this article, we'll explain how to turn a photo into a lithophane. But first, let's explore what types of photos make the best lithophanes. " />
                            
                            {/* <Advantagesheading /> */}
                            {/* <AdvantagesImages images1={pData[5].img1}  images2={pData[5].img2} images3={pData[5].img3} images4={pData[5].img4}   /> */}


                        </div>
                    </div>
                </div>

            </div>

            <Footer />
    </>
  )
}

export default Lithographicprinting;