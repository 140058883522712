import React from 'react';
import Blankspace from '../Basic components/Blankspace';
import ComingsoonHero from './ComingsoonHero';


const ComingSoonPage = () => {
  return (
    <>
        <Blankspace/>
        <ComingsoonHero/>
    </>
  )
}

export default ComingSoonPage;