import React from 'react';
import './abouthero.css';

const Aboutushero = () => {
  return (
    <>
      <div className='hero-container'>
        <div className='aboutus-heading'>About Us</div>
      </div>
    </>
  )
}

export default Aboutushero