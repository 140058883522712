const nullimg="https://img.freepik.com/free-photo/quadcopter-flying-nature_231208-10459.jpg";


const shopdata=[
    {
        productimg:nullimg,
        productname:"Product Name",
        productprice:"1200/-",
        amazonproduct:"",
    },
    {
        productimg:nullimg,
        productname:"Product Name",
        productprice:"1200/-",
        amazonproduct:"",
    },
   
    {
        productimg:nullimg,
        productname:"Product Name",
        productprice:"1200/-",
        amazonproduct:"",
    },
   
    {
        productimg:nullimg,
        productname:"Product Name",
        productprice:"1200/-",
        amazonproduct:"",
    },
   
    {
        productimg:nullimg,
        productname:"Product Name",
        productprice:"1200/-",
        amazonproduct:"",
    },
   
    {
        productimg:nullimg,
        productname:"Product Name",
        productprice:"1200/-",
        amazonproduct:"",
    },
   

   
   
]

export default shopdata;